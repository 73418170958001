import React from "react";

function Footer() {
  return (
    <footer
      class="flex items-center justify-center bg-blueGray-200 py-16 pb-6 min-h-[60vh]"
      id="footer"
    >
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap text-left lg:text-left justify-between">
          <div class="w-full lg:w-6/12 px-4">
            {/* <h4 class="text-3xl fonat-semibold text-blueGray-700">
            ENPLAST
        </h4> */}
            <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
              Sustainable Choices, Infinite Possibilities
              <br />
              "Forging a sustainable path toward a cleaner, greener tomorrow."
            </h5>
            {/* <div class="mt-6 lg:mb-0 mb-6">
          <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i class="fab fa-twitter"></i></button><button class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i class="fab fa-facebook-square"></i></button><button class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i class="fab fa-dribbble"></i></button><button class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i class="fab fa-github"></i>
          </button>
        </div> */}
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="flex flex-wrap items-top mb-6">
              <div class="w-full lg:w-4/12 px-4 ml-auto">
                <span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Our Services
                </span>
                <ul class="list-unstyled">
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      // href="https://www.creative-tim.com/presentation?ref=njs-profile"
                    >
                      Collection Services
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      // href="https://blog.creative-tim.com?ref=njs-profile"
                    >
                      AFR
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      // href="https://www.github.com/creativetimofficial?ref=njs-profile"
                    >
                      Workshop Training
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      // href="https://www.creative-tim.com/bootstrap-themes/free?ref=njs-profile"
                    >
                      EPR Services
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      // href="https://www.creative-tim.com/bootstrap-themes/free?ref=njs-profile"
                    >
                      Product Development
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div class="w-full lg:w-4/12 px-4">
                <span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Product Development
                </span>
                <ul class="list-unstyled">
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="https://github.com/creativetimofficial/notus-js/blob/main/LICENSE.md?ref=njs-profile"
                    >
                      Our Products
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="https://creative-tim.com/terms?ref=njs-profile"
                    >
                      Our Services
                    </a>
                  </li>
                </ul>
              </div> */}
              <div class="w-full lg:w-4/12 px-4">
                <span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Contact Us
                </span>
                <ul class="list-unstyled">
                  <li class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm italic">
                    Aanaykamparambu, Bypass Rd, Near PHC ,Kunissery P.O,Palakkad
                    678681
                  </li>
                  <li class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm italic">
                    +91 8921898957, <br /> +91 7907428080, <br /> +91 9567886776
                  </li>
                  <li class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm italic">
                    admin@enplastsolutions.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-6 border-blueGray-300"></hr>
        <div class="flex flex-wrap items-center md:justify-between justify-center">
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2024</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
