import React from "react";
import ServiceTile from "./ServiceTile";

function Epr() {
  return (
    <div className=" bg-gray-100 w-full min-h-screen px-20 py-28" id="about-us">
      <div className="text-5xl font-bold p-6 tracking-wider">EPR</div>
      <div className="grid grid-cols-4">
        <div className=" md:col-span-4 pr-20 pl-10">
          {/* <span className="text-xl font-semibold pl-6 tracking-wider">
            Welcome to EN PLAST
          </span> */}
          <br></br>
          <span className="text-lg pl-6">
            Extended Producer Responsibility (EPR) represents a progressive
            strategy in waste management that shifts the accountability for
            products and packaging at the end of their lifecycle from consumers
            and municipalities back to the producers. EPR embodies the principle
            of "polluter pays," wherein producers assume the financial and
            logistical responsibility for managing the environmental impact of
            their goods.
          </span>
          <br></br>
          <span className="text-xl font-semibold pl-6 tracking-wider">
            Why EPR Matters:
          </span>
          <br></br>
          <span className="text-lg pl-6">
            Environmental Preservation: EPR incentivizes producers to design
            products with end-of-life considerations, promoting eco-friendly
            materials and efficient recycling methods. By curbing waste and
            advocating resource efficiency, EPR contributes to biodiversity
            conservation and pollution mitigation. Promotion of Circular
            Economy: EPR fosters the transition to a circular economy by
            encouraging product reuse, remanufacturing, and recycling. This
            shift from a linear "take-make-dispose" model to a circular one
            minimizes resource depletion and encourages sustainable consumption
            practices. Regulatory Compliance: With governments globally
            prioritizing environmental protection and waste reduction, EPR
            legislation is gaining momentum. By proactively embracing EPR
            principles, businesses can stay compliant with regulations, enhance
            brand reputation, and mitigate potential liabilities.
          </span>
          <br></br>
          <span className="text-xl font-semibold pl-6 tracking-wider">
            Vision:Our Approach to EPR:
          </span>
          <br></br>
          <span className="text-lg pl-6">
            At EN PLAST, we are dedicated to assisting businesses in navigating
            the complexities of EPR implementation. Our team of experts provides
            solutions to producers in meeting their EPR obligations efficiently
            and effectively. Developing waste collection and recycling
            infrastructure, we offer comprehensive support to ensure compliance
            and maximize environmental impact.
          </span>
        </div>
        {/* <div className=" md:col-span-1 bg-en-green m-4 flex flex-col items-center justify-center gap-10">
          <div className=" text-center text-4xl font-semibold text-white">
            Get in touch today
          </div>
          <button className="transition ease-in-out  bg-black text-white font-bold md:tracking-wider hover:scale-110 px-4 py-2 flex items-center rounded-full">
            CONTACT US
          </button>
        </div> */}
      </div>
      {/* <div class="flex items-center justify-center text-black p-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
                {
                    services.map(each => <ServiceTile service={each} />)
                }		
            </div>
        </div> */}
    </div>
  );
}

export default Epr;
