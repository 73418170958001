import React, { useState } from 'react'

function ProjectTile({project}) {

const [hover, sethover] = useState(false)

  return (
    <div className='overflow-hidden z-100 w-full'> 
        <div class="overflow-hidden cursor-pointer 
        transition ease-in-out  bg-cover w-full md:h-64 h-36 hover:scale-110 " 
            style={{ 
                backgroundImage: `url(${project.image})` 
            }}

            onMouseEnter={() => sethover(true)}
            onMouseLeave={() => sethover(false)}
        >
			<div class=" items-center">
				{/* <span class="flex items-center justify-center w-6 h-6 rounded-full bg-pink-100">
					<svg class="w-4 h-4 stroke-current text-pink-600"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</span> */}
				<span class={`ml-2 text-sm font-medium text-gray-500 ${hover ? 'invisible' : ''}`}>{project.name}</span>
			</div>
			<div class={`flex w-full h-full justify-center items-center md:text-9xl text-5xl font-bold text-white ${hover ? '' : 'invisible'} `}><p>{project?.name}</p></div>
			{/* <div class="flex text-xs mt-3 font-medium">
				<span class="text-green-500">+8%</span>
				<span class="ml-1 text-gray-500">last 14 days</span>
			</div> */}
		</div>
        </div>
  )
}

export default ProjectTile