import React from "react";
import ServiceTile from "./ServiceTile";

function Services() {
  const navigate = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <div className=" bg-gray-100 w-full min-h-screen px-20 py-28" id="about-us">
      <div className="text-5xl font-bold p-6 tracking-wider">About Us</div>
      <div className="grid grid-cols-4">
        <div className=" md:col-span-3 pr-20 pl-10">
          {/* <span className="text-xl font-semibold pl-6 tracking-wider">
            Welcome to EN PLAST
          </span>
          <br></br>
          <span className="text-lg pl-6">
            EN PLAST takes charge in redefining plastic waste management,
            offering effective and efficient solutions for waste management. Our
            commitment extends beyond solutions; we prioritize educating and
            advocating for the importance of recycling. By empowering
            individuals with knowledge, we aim to create a culture of
            responsible waste management.
          </span> */}
          <br></br>
          <span className="text-xl font-semibold pl-6 tracking-wider">
            Mission:
          </span>
          <br></br>
          <span className="text-lg pl-6">
            "At EN PLAST, we're on a mission to tackle plastic waste head-on.
            Our focus is on finding innovative solutions to manage waste
            effectively and responsibly. We're not just about getting rid of
            trash; we're about turning it into something useful. Through
            innovation and hard work, we're determined to make our planet
            cleaner and greener for everyone."
          </span>
          <br></br>
          <span className="text-xl font-semibold pl-6 tracking-wider">
            Vision:
          </span>
          <br></br>
          <span className="text-lg pl-6">
            "Our vision is to transform the perception of waste from a burden to
            an opportunity, where every discarded item holds potential. We aim
            to lead the way towards a future where recycling is easy and part of
            everyday life for everyone. Through collaborative efforts with
            communities and businesses, we are dedicated to cultivating a
            sustainable ecosystem where waste is not only minimized but
            repurposed and recycled effectively. Our goal is to create a lasting
            impact on the environment, making life better for both current and
            future generations by taking care of our planet and making sure we
            don't waste valuable resources.”
          </span>
        </div>
        <div className=" md:col-span-1 bg-en-green m-4 flex flex-col items-center justify-center gap-10">
          <div className=" text-center text-4xl font-semibold text-white">
            Get in touch today
          </div>
          <button
            onClick={() => {
              navigate("footer");
            }}
            className="transition ease-in-out  bg-black text-white font-bold md:tracking-wider hover:scale-110 px-4 py-2 flex items-center rounded-full"
          >
            CONTACT US
          </button>
        </div>
      </div>
      {/* <div class="flex items-center justify-center text-black p-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
                {
                    services.map(each => <ServiceTile service={each} />)
                }		
            </div>
        </div> */}
    </div>
  );
}

export default Services;
