import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Services from "./components/Services";
import Purpose from "./components/Purpose";
import Projects from "./components/Projects";
import Epr from "./components/Epr";
import Team from "./components/Team";

function App() {
  return (
    <div className="App w-full">
      <Navbar />
      <Purpose />
      <Services />
      <Projects />
      <Epr />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
