import React, { useEffect, useRef } from "react";
import ProjectTile from "./ProjectTile";
import { useAnimation, useInView, motion } from "framer-motion";

function Projects() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView]);

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="w-full min-h-screen px-20 bg-slate-50"
      id="services"
    >
      <div className="text-5xl font-bold p-6 tracking-wider">SERVICES</div>
      <div className="grid grid-cols-3 min-h-screen ">
        <div
          ref={ref}
          className="md:col-span-1 bg-en-green m-10 flex items-center cursor-pointer transition ease-in-out hover:scale-110"
        >
          <div className=" font-bold text-5xl text-center md:tracking-wider text-white">
            COLLECTION SERVICES
          </div>
        </div>
        <div className="md:col-span-1 grid grid-rows-2 min-h-screen m-10">
          <div className=" md:row-span-1 bg-black m-5 flex items-center justify-center cursor-pointer transition ease-in-out hover:scale-110">
            <div className=" font-bold text-5xl text-center md:tracking-wider text-white">
              AFR
            </div>
          </div>
          <div className="md:row-span-1 bg-slate-50 flex items-center justify-center cursor-pointer transition ease-in-out hover:scale-110">
            <div className=" font-bold text-5xl text-center md:tracking-wider text-en-green">
              WORKSHOP & TRAINING
            </div>
          </div>
        </div>
        <div className="md:col-span-1 grid grid-rows-2 min-h-screen m-10">
          <div className=" md:row-span-1 bg-en-green m-5 flex items-center justify-center cursor-pointer transition ease-in-out hover:scale-110">
            <div className=" font-bold text-5xl text-center md:tracking-wider text-black">
              EPR SERVICES
            </div>
          </div>
          <div className="md:row-span-1 bg-black m-5 flex items-center justify-center cursor-pointer transition ease-in-out hover:scale-110">
            <div className=" font-bold text-4xl text-center md:tracking-wider text-white">
              PRODUCT DEVELOPMENT
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Projects;
