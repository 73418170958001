import React from "react";
import Ramshid from "./../assets/img/ramshid.jpg";
import Mithun from "./../assets/img/mithun.jpg";
import Rizwan from "./../assets/img/rizwan.jpg";

function Team() {
  return (
    <div className="w-full min-h-screen px-20 bg-white">
      <div className="text-5xl font-bold p-6 tracking-wider">MEET THE TEAM</div>
      <div className="grid grid-cols-3 m-10 gap-4">
        <div className="flex flex-col items-center">
          <img src={Ramshid} className=" rounded-lg max-w-xs h-[24em]" />
          <p className="pt-5 font-bold text-2xl">Ramshid</p>
          {/* <p className="font-normal text-sm">co-founder</p> */}
        </div>
        <div className="flex flex-col items-center">
          <img src={Mithun} className=" rounded-lg max-w-xs  h-[24em]" />
          <p className="pt-5 font-bold text-2xl">Midhun</p>
          {/* <p className="font-normal text-sm">co-founder</p> */}
        </div>
        <div className="flex flex-col items-center">
          <img src={Rizwan} className=" rounded-lg max-w-xs  h-[24em]" />
          <p className="pt-5 font-bold text-2xl">Rizwan</p>
          {/* <p className="font-normal text-sm">co-founder</p> */}
        </div>
      </div>
    </div>
  );
}

export default Team;
