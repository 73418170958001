import React from "react";
import landing from "./../assets/img/landing.jpg";

function Purpose() {
  const navigate = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <div id="home">
      <div
        className="min-h-screen w-full md:bg-cover bg-fit bg-center bg-no-repeat bg-fixed relative"
        style={{
          backgroundImage: `url(${landing})`,
        }}
      >
        <div className="absolute inset-0 bg-gray-700 opacity-75 rounded-md"></div>
        <div className="grid items-center min-h-screen md:gap-16 gap:5 absolute inset-0">
          {/* <p className='md:text-8xl text-6xl text-red-800 font-bold pb-16' ></p>                 */}
          <p className="md:text-5xl text-4xl font-semibold text-black">
            <span className="text-white text-8xl font-bold tracking-wider">
              CHOOSE US
            </span>
            <span className=" text-en-green text-10xl font-bold tracking-wider">
              ,
            </span>
            <br></br>
            <span className="text-white text-8xl font-bold tracking-wider">
              To Transform The Planet
            </span>
            {/* <span className='text-green-700 text-7xl font-bold' >BAG THE PLASTIC<br></br> SAVE THE PLANET.</span>  */}
            {/* <span className='text-blue-800 text-6xl font-bold underline' >SAVE</span>  */}
            <br></br>
            {/* providing high quality design  */}
            {/* <br></br>and services to our clients */}
            {/* <br></br> THE PLANET. */}
          </p>
          {/* <p className="m-5 bg-gray-700 opacity-75 rounded-lg w-1/2 p-10"> */}
          <p className=" text-white font-normal text-xl tracking-wider px-5 py-3 bg-gray-700 rounded-lg opacity-75 w-3/4">
            EN PLAST takes charge in redefining plastic waste management,
            offering effective and efficient solutions for waste management. Our
            commitment extends beyond solutions; we prioritize educating and
            advocating for the importance of recycling. By empowering
            individuals with knowledge, we aim to create a culture of
            responsible waste management.
            <button
              className="transition ease-in-out bg-black text-en-green font-bold md:tracking-wider hover:scale-110 px-6 py-4 my-6 flex items-center rounded-full"
              onClick={() => {
                navigate("footer");
              }}
            >
              CONTACT US
            </button>
          </p>

          {/* </p> */}
        </div>
      </div>
    </div>
  );
}

export default Purpose;
